.modal-wide {
    width: 95%;
    height: 80%;
    align-content: center;
    max-width: none!important;
}

/* .input-group-prepend {
    width: 11%;
    height: 29px ;
    font-weight: bold;
  }

  .input-group-prepend label {
    width: 100%;
    overflow: hidden;
  } */
