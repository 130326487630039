
#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 2;
    cursor: pointer;
  }
  
  #text{
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
  }
  
  
  