.form {
  display: flex;
  flex-wrap: wrap;
  font-family: Arial;
}

table {
  /* border: 1px solid #000000;    */
  border-collapse: collapse;
  padding: 0;
}

.popoverError {
  padding: 0;
  border: 2px solid var(--optum-error-state-red) !important;
  font-size: 10px !important;
}

.popoverError > .popover-body {
  padding: 2px;
}

.ub04DateInput {
  width: 100%;
  border: none;
  text-align: center;
}
.ub04DateInput2 {
  width: 100%;
  border: none;
  text-align: center;
  background-color: #e0e0e0;
}

/* td {  
    border: 1px solid #D0D0D0;   
}   */

.smallNumberLeftAligned {
  font-size: 7px;
  vertical-align: top;
  text-align: left;
}

.smallText {
  font-size: 7px;
  vertical-align: top;
}

.middleText {
  vertical-align: center;
  font-size: 7px;
}

.boldSmallLeftAlighned {
  font-size: 6px;
  vertical-align: center;
  font-weight: bold;
  text-align: left;
}

.boldSmallLRightAlighned {
  font-size: 6px;
  vertical-align: center;
  font-weight: bold;
  text-align: right;
}

.grayBackground {
  background-color: #e0e0e0;
}

.blackBackgroundWhiteText {
  background-color: #000000;
  color: #ffffff;
  font-weight: bold;
}

.leftRightBottomBorder {
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
}

.leftRightBorder {
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}

.leftBorder {
  border-left: 1px solid #000000;
}

.rightBorder {
  border-right: 1px solid #000000;
}

.rightDottedBorder {
  border-right: 1px dotted #00000036;
}

.topBorder {
  border-top: 1px solid #000000;
}

.bottomBorder {
  border-bottom: 1px solid #000000;
}

.bottomBorderGrey {
  border-bottom: 1px solid #d0d0d0;
}

.topBottomBoldBorder {
  border-bottom: 2px solid #000000;
  border-top: 2px solid #000000;
}

/* .right.dotted-spaced {
    background-image: linear-gradient(to bottom, #000 10%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: 1px 8px;
    background-repeat: repeat-y;
}

.right {
    float: right;
    padding: 40px 10px;
}
.right.dotted {
    border-right: 2px #000 dotted;
    border-top: none;
} */

/* .greyRectangle {
    background: url(images/greyRectangle.png);
    background-size: 4px 14px;
} */

.boldLargeItalic {
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.boldLargeGrey {
  font-size: 15px;
  font-weight: bold;
  color: #e0e0e0;
  text-align: center;
}

.fontSmall {
  font-size: 7px;
}
x table td {
  color: #000000;
}

#billType {
  background-color: #000000;
  color: #ffffff;
  font-size: 7px;
  vertical-align: top;
  font-weight: bold;
}

#ub04row0 > td {
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  padding: 0;
  height: 10px;
  width: 2px;
}

#ub04row1 > td {
  padding: 0;
  height: 15px;
}

#ub04row2 > td {
  padding: 0;
  height: 15px;
}

#ub04row3 > td {
  padding: 0;
  height: 15px;
}

#ub04row4 > td {
  padding: 0;
  height: 15px;
}

#ub04row5 > td {
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
  height: 15px;
}

#ub04row6 > td {
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
  height: 15px;
}

#ub04row7 > td {
  /* border-left: 1px solid #000000; 
    border-right: 1px solid #000000; 
    border-bottom: 1px solid #000000; */
  height: 6px;
  border-bottom: none;
  padding: 0;
}

#ub04row8 > td {
  height: 6px;
  padding: 0;
  border-top: none;
}

#ub04row9 > td {
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
  border-top: 1px solid #000000;
  height: 15px;
}

#ub04row10 > td {
  height: 6px;
  border-bottom: none;
  font-size: 7px;
  padding: 0;
}

#ub04row11 > td {
  height: 6px;
  padding: 0;
  border-top: none;
  font-size: 7px;
  text-align: center;
}

#ub04row12 > td {
  height: 15px;
  border-top: 1px solid #000000;
}

#ub04row13 > td {
  height: 15px;
}

#ub04row14 > td {
  height: 6px;
  border-bottom: none;
  font-size: 7px;
  border-top: 1px solid #000000;
  padding: 0;
}

#ub04row15 > td {
  height: 6px;
  padding: 0;
  border-top: none;
  font-size: 7px;
  text-align: center;
}

#ub04row16 > td {
  height: 15px;
}

#ub04row17 > td {
  height: 15px;
}

#ub04row18 > td {
  height: 15px;
}
#ub04row19 > td {
  height: 15px;
  border-bottom: 1px solid #000000;
}

#ub04row20 > td {
  height: 15px;
  border-bottom: 1px solid #000000;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  border-top: 1px solid #000000;
  vertical-align: center;
  font-size: 6px;
  padding: 0;
}

#ub04rowOdd > td {
  height: 15px;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}

#ub04rowEven > td {
  height: 15px;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  background-color: #e0e0e0;
}

#ub04row43 > td {
  height: 15px;
  padding: 0;
}

#ub04row44 > td {
  height: 15px;
  background-color: #e0e0e0;
  vertical-align: center;
  font-size: 7px;
  padding: 0;
}

#ub04row45 > td {
  height: 15px;
  vertical-align: center;
  font-size: 7px;
  padding: 0;
  border-top: 1px solid #000000;
}

#ub04row46 > td {
  height: 15px;
  vertical-align: center;
  font-size: 7px;
  padding: 0;
  background-color: #e0e0e0;
}

#ub04row47 > td {
  height: 15px;
  vertical-align: center;
  font-size: 7px;
  padding: 0;
}

#ub04row48 > td {
  height: 15px;
  vertical-align: center;
  font-size: 7px;
  border-top: 1px solid #000000;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  background-color: #e0e0e0;
  padding: 0;
}

#ub04row49 > td {
  height: 15px;
  padding: 0;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  border-top: 1px solid #000000;
}

#ub04row50 > td {
  height: 15px;
  padding: 0;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  background-color: #e0e0e0;
}

#ub04row51 > td {
  height: 15px;
  padding: 0;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}

#ub04row52 > td {
  height: 15px;
  padding: 0;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  border-top: 1px solid #000000;
  vertical-align: center;
  font-size: 7px;
  background-color: #e0e0e0;
}

#ub04row53 > td {
  height: 15px;
  padding: 0;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  border-top: 1px solid #000000;
}

#ub04row54 > td {
  height: 15px;
  padding: 0;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  background-color: #e0e0e0;
}

#ub04row55 > td {
  height: 15px;
  padding: 0;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}

#ub04row56 > td {
  height: 15px;
  border-top: 1px solid #000000;
  padding: 0;
}

#ub04row57 > td {
  height: 15px;
  border-top: 1px solid #000000;
  padding: 0;
}

#ub04row58 > td {
  height: 6px;
  border-bottom: none;
  padding: 0;
  border-top: 1px solid #000000;
}

#ub04row59 > td {
  height: 6px;
  padding: 0;
  border-top: none;
}

#ub04row60 > td {
  height: 6px;
  border-bottom: none;
  padding: 0;
  border-top: 1px solid #000000;
  font-size: 7px;
}

#ub04row61 > td {
  height: 6px;
  padding: 0;
  border-top: none;
  font-size: 7px;
}

#ub04row62 > td {
  height: 15px;
  border-top: 1px solid #000000;
  padding: 0;
}

#ub04row63 > td {
  height: 6px;
  border-bottom: none;
  padding: 0;
  border-top: 1px solid #000000;
  font-size: 7px;
}

#ub04row64 > td {
  height: 6px;
  padding: 0;
  border-top: none;
  font-size: 7px;
}

#ub04row65 > td {
  height: 15px;
  border-top: 1px solid #000000;
  padding: 0;
}

#ub04row66 > td {
  height: 6px;
  border-bottom: none;
  padding: 0;
  border-top: 1px solid #000000;
  font-size: 7px;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}

#ub04row67 > td {
  height: 6px;
  padding: 0;
  border-top: none;
  font-size: 7px;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}

#ub04row68 > td {
  height: 15px;
  border-top: 1px solid #000000;
  padding: 0;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}

#ub04row69 > td {
  height: 15px;
  border-top: 1px solid #000000;
  padding: 0;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}

#ub04row70 > td {
  height: 15px;
  border-top: 1px solid #000000;
  padding: 0;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}

#ub04row71 > td {
  height: 15px;
  border-top: 1px solid #000000;
  padding: 0;
  font-size: 7px;
  vertical-align: top;
}

#ub04row72 > td {
  height: 15px;
  padding: 0;
  font-size: 7px;
  vertical-align: top;
}

/* .input {
  border: none;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  font-size: 12px;
} */

#placeholderInput::placeholder {
  font-size: 15px;
  font-weight: bold;
  color: #e0e0e0;
  text-align: center;
}
