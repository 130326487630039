/* *,
::after,
::before {
  box-sizing: content-box;
} */

#claimButtonMenu {
  position: fixed;
  top: 450px;
  right: 0;
  height: 300px;
  z-index: 2;
}

#acceptButton {
  background-color: var(--optum-green-2);
}

#claimButtonMenu > #autoSignButton {
  background-color: var(--optum-green-2);
  width: 150px;
}

#rejectButton {
  background-color: var(--optum-error-state-red);
}

#pendButton {
  background-color: var(--optum-brand-gold);
  color: var(--optum-gray-1);
}

#editButton {
  background-color: #a5a5a5;
  color: var(--optum-gray-1);
}

#claimButtonMenu > button {
  width: 115px;
  height: 30px;
  border: none;
  font-weight: 800;
  padding: 5px;
  line-height: 1;
  border-radius: 0;
  font-size: 20px;
  margin: 25px;
}

#claimButtonMenu {
  max-width: 600px;
}

#acceptButton.disabled,
#acceptButton:disabled {
  background-color: var(--optum-gray-6);
  color: var(--optum-white);
  opacity: 1;
}

#autoSignButton.disabled,
#autoSignButton:disabled {
  background-color: var(--optum-gray-6);
  color: var(--optum-white);
  opacity: 1;
}

#rejectButton.disabled,
#rejectButton:disabled {
  background-color: var(--optum-gray-6);
  color: var(--optum-white);
  opacity: 1;
}

#pendButton.disabled,
#pendButton:disabled {
  background-color: var(--optum-gray-6);
  color: var(--optum-white);
  opacity: 1;
}

#editButton.disabled,
#editButton:disabled {
  background-color: var(--optum-gray-6);
  color: var(--optum-white);
  opacity: 1;
}

#autoSignImageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

#electronicSignatureHeader {
  position: absolute;
  top: 42px;
  left: 45px;
  font-weight: 800;
  font-size: 15px;
}

#electronicSignature {
  position: absolute;
  top: 60px;
  font-size: 10px;
  width: 100%;
  text-align: center;
}

.arrowLine {
  width: 6px;
  height: 462px;
  position: absolute;
  border-right: 1px solid var(--optum-red-2);
}

.bgWhite {
  background-color: var(--optum-white);
}

.rotatedTextContainer {
  width: 12px;
  height: 462px;
  overflow: hidden;
}

.rotatedText {
  transform: translateX(-49.5%) translateY(-50%) rotate(-90deg);
  height: 12px;
  width: 462px;
  top: 231px;
  left: 3px;
  position: relative;
  text-align: center;
  font-weight: 800;
  font-size: 11px;
}

.text {
  font-family: "Arial";
  color: var(--optum-red-2);
  font-weight: 700;
  margin-top: 0;
}

.header {
  font-size: 15px;
}

.subheader {
  font-size: 8px;
  font-weight: 600;
}

.label {
  font-size: 7.5px;
  font-weight: 600;
  margin: 0;
}

.cellLabel {
  vertical-align: top;
  display: inline-block;
  line-height: 1.4;
}

.topWarning {
  margin-bottom: 4px;
}

.characterInput {
  border: 2px solid var(--optum-red-2);
  width: 8px;
  height: 10px;
  padding: 0;
  box-sizing: content-box !important;
}

.checkboxNumber {
  margin: 0;
  font-size: 8px;
  position: absolute;
}

.checkboxLabel {
  margin: 0;
  font-size: 7.5px;
  vertical-align: top;
  font-weight: 600;
  max-width: 60px;
  display: inline;
}

.checkboxTitle {
  max-width: 60px;
  display: inline-block;
}

.checkboxLabelMargin {
  margin-left: 18px;
}

.checkboxTitle2 {
  min-width: 55px;
}

.checkboxItalicLabel {
  font-style: italic;
  min-width: 65px;
}

#tricare {
  margin-right: 30px;
}

label[for="conditionRelated"] {
  margin-bottom: 24px;
}

.checkboxContainer {
  margin-right: 4px;
  box-sizing: content-box !important;
  min-height: 22px;
}

.checkbox {
  margin: 0;
  vertical-align: middle;
  box-sizing: content-box !important;
}

.checkboxTop {
  margin: 0;
  vertical-align: bottom;
  box-sizing: content-box !important;
}

.checkboxListLabel {
  font-size: 8px;
  vertical-align: middle;
  margin-right: 2px;
  font-weight: 600;
  padding-left: 3px;
  box-sizing: content-box;
  display: inline;
}

.checkboxListLabelLeft {
  font-size: 8px;
  vertical-align: middle;
  margin-right: 13px;
  font-weight: 600;
  padding-left: 0;
  box-sizing: content-box;
  display: inline;
}

.checkboxListLabelTop {
  font-size: 8px;
  vertical-align: top;
  margin-right: 2px;
  font-weight: 600;
  padding-left: 0;
  box-sizing: content-box;
  display: inline;
}

.checkboxListContainer {
  min-width: 40px;
}

.input {
  border: none;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  font-size: 12px;
}

.inputBottomBorder {
  border: none;
  border-bottom: 1px solid var(--optum-red-2);
  box-sizing: border-box;
  width: 100%;
  height: auto;
}

.inputContainer {
  height: 30px;
}

.inputContainer.doubleHeight {
  height: 60px;
}

.inputInline {
  border: none;
  box-sizing: border-box;
  height: auto;
  vertical-align: bottom;
}

.rowContainer {
  display: flex;
  flex-direction: row;
}

.colContainer {
  display: flex;
  flex-direction: column;
}

.spaceBetween {
  justify-content: space-between;
}

.spaceAround {
  justify-content: space-around;
}

.alignCenter {
  align-items: center;
}

.flexEnd {
  justify-content: flex-end;
}

.bold {
  font-weight: 900;
}

.extraBold {
  text-shadow: 1px 0 var(--optum-red-2);
  letter-spacing: 1px;
  font-weight: 900;
}

.nowrap {
  white-space: nowrap;
}

.center {
  text-align: center;
}

.formTable {
  border: 2px solid var(--optum-red-2) !important;
}

.inputTable {
  border: none;
}

.inputTableField {
  width: 90px;
  border-top: none;
  border-right: none;
  border-left: 1px solid var(--optum-red-2);
  border-bottom: 1px solid var(--optum-red-2);
  margin-left: 2px;
}

.inputTableLabel {
  width: 9px;
  align-self: flex-end;
}

.popoverError {
  padding: 0;
  border: 2px solid var(--optum-error-state-red) !important;
  font-size: 10px !important;
}

.popoverError > .popover-body {
  padding: 2px;
}

.formTable table,
table.formTable tr,
table.formTable td {
  border: 1px solid var(--optum-red-2) !important;
  border-collapse: collapse !important;
}

table {
  width: 100%;
  table-layout: fixed;
}

.inputTable > tr > td,
.inputTable > tr {
  border: none;
}

td.disabled {
  background-color: #fde8dd;
}

input.disabled {
  background-color: #fde8dd;
}

table.formTable tr {
  line-height: 1;
}

table.formTable td {
  vertical-align: top;
  padding: 4px;
}

.formColumn1 {
  width: 274px;
}

.formColumn2 {
  width: 192px;
}

.formColumn3 {
  width: 189px;
}

.relativePositioningPlaceholder {
  position: relative;
  width: 0;
  height: 0;
}

#autoAccidentStatePosition {
  width: 60px;
  position: relative;
  top: -70px;
  left: 140px;
}

.rowNumber {
  position: absolute;
  font-size: 22px;
  left: -20px;
  top: 5px;
}

.datepicker {
  text-align: center;
  border: none;
  width: 100px;
}

.datepickerBottomBorder {
  text-align: center;
  border: none;
  border-bottom: 1px solid var(--optum-red-2);
}

.datepicker.small {
  font-size: 8px !important;
}

.emptyDatepicker {
  font-weight: 100;
  color: var(--optum-gray-1);
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
}

input {
  font-size: 12px;
  font-family: "Helvetica Nueue", Helvetica, sans-serif !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  margin: 0;
}

textarea {
  font-size: 11px;
  font-family: "Helvetica Nueue", Helvetica, sans-serif !important;
  resize: none !important;
}

#icdInput {
  width: 70px;
}

.hr {
  border-top: 1px dashed var(--optum-red-2) !important;
  width: 100%;
  margin: 0 !important;
}

.dateInput {
  width: 25px;
}

#taxIdTypeEIN > label {
  padding-left: 2px;
}

#innerTableHeaders {
  height: 34px;
}

#innerTableHeaders > td {
  padding-top: 0;
  padding-bottom: 0;
}

#member\.genderM {
  margin-right: 25px;
}

#insured_genderM {
  margin-right: 35px;
}

#patientRelationshipSelf {
  margin-left: 8px;
}

#anotherHealthBenefitPlanYES {
  margin-left: 19px;
}

.verticalRule {
  height: 7px;
  border: none;
  border-right: 1px solid var(--optum-red-2);
}

.verticalRule.solid {
  height: 16px;
  border: none;
  border-right: 1px solid var(--optum-red-2);
}

.verticalRule.dotted {
  height: 16px;
  border: none;
  border-right: 1px dotted var(--optum-red-2);
}

.verticalRule.dotted.tall {
  height: 26px;
  border: none;
  border-right: 1px dotted var(--optum-red-2);
}

.verticalRule.dashed {
  height: 16px;
  border: none;
  border-right: 1px dashed var(--optum-red-2);
}

.verticalRule.dashed.tall {
  height: 26px;
  border: none;
  border-right: 1px dashed var(--optum-red-2);
}

.shadedBg {
  background-color: #fde8dd;
}

.dividedInputs {
  background-image: linear-gradient(to bottom, #fde8dd 45%, #ffffff 45%);
  background-image: -webkit-linear-gradient(
    to bottom,
    #ffd51a 45%,
    #fac815 45%
  );
  background-image: -o-linear-gradient(to bottom, #ffd51a 45%, #fac815 45%);
  background-image: -moz-linear-gradient(to bottom, #ffd51a 45%, #fac815 45%);
  background-image: -ms-linear-gradient(to bottom, #ffd51a 45%, #fac815 45%);
}

.dividedCell {
  height: 16px;
  padding: 0;
  padding-top: 16px;
  background-image: linear-gradient(to bottom, #fde8dd 50%, #ffffff 50%);
  background-image: -webkit-linear-gradient(
    to bottom,
    #ffd51a 50%,
    #fac815 50%
  );
  background-image: -o-linear-gradient(to bottom, #ffd51a 50%, #fac815 50%);
  background-image: -moz-linear-gradient(to bottom, #ffd51a 50%, #fac815 50%);
  background-image: -ms-linear-gradient(to bottom, #ffd51a 50%, #fac815 50%);
}

tr.dividedRow > td.dividedCell {
  padding-bottom: 0;
  padding-top: 0;
  height: 20px;
}

#cptHeader {
  width: 55px;
  margin-right: 5px;
}

#acceptAssignmentNO {
  position: relative;
  left: -45px;
}

#outsideLabYES {
  margin-left: 16px;
  margin-right: 10px;
}

#genderWrapper {
  width: 200px;
}

#row1 > td {
  padding-bottom: 0;
}

#row2 > td {
  padding-bottom: 0;
}

#row6 > td {
  padding-bottom: 0;
  height: 28px;
}

#row7 > td {
  padding-bottom: 0;
  height: 28px;
}

#row8 > td {
  padding-bottom: 0;
  height: 28px;
}

#row9 > td {
  padding-bottom: 0;
  height: 28px;
}

#row12 > td {
  padding-bottom: 0;
  height: 42px;
}

#row13 > td {
  padding-bottom: 0;
  height: 42px;
}

#row14 > td {
  padding-bottom: 0;
  height: 40px;
}

#row15 > td {
  padding-bottom: 0;
  height: 42px;
}

#row16 > td {
  padding-bottom: 0;
  height: 42px;
}

#row24 > td {
  padding-bottom: 0;
  height: 39px;
}

#row25 > td {
  padding-bottom: 0;
  height: 65px;
}

#row26 > td {
  padding-bottom: 0;
  height: 22px;
}

.npiBackground {
  background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='120px'><text x='40' y='22' fill='%23fde8dd' font-weight='bold' font-size='28' font-family='Arial, Helvetica, sans-serif'>NPI</text></svg>");
}

#modifierHeader {
  width: 120px;
  margin-right: 4px;
}

.middleAlign {
  display: flex;
  align-items: center;
}

#physicianSignatureDate {
  width: 60px;
  padding-left: 3px;
}

/* Convoluted custom checkbox styles */

/* Base for label styling */
.cmsRadio[type="radio"]:not(:checked),
.cmsRadio[type="radio"]:checked {
  display: none;
}
[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  position: relative;
  padding-left: 17px;
  cursor: pointer;
}
.cmsRadio[type="radio"]:not(:checked) + label.topLabel,
.cmsRadio[type="radio"]:checked + label.topLabel {
  position: relative;
  padding-left: 0;
  margin-left: 4px;
  margin-bottom: 4px;
  cursor: pointer;
}

/* checkbox aspect */
.cmsRadio[type="radio"]:not(:checked) + label:before,
.cmsRadio[type="radio"]:checked + label:before {
  content: "";
  position: absolute;
  box-sizing: content-box;
  left: 0;
  bottom: -3px;
  width: 13px;
  height: 13px;
  border: 2px solid var(--optum-red-2);
  background: var(--optum-white);
}

/* checkbox aspect */
.cmsRadio[type="radio"]:not(:checked) + label.topLabel:before,
.cmsRadio[type="radio"]:checked + label.topLabel:before {
  content: "";
  position: absolute;
  box-sizing: content-box;
  left: 0;
  top: 15px;
  width: 13px;
  height: 13px;
  border: 2px solid var(--optum-red-2);
  background: var(--optum-white);
}

/* checked mark aspect */
.cmsRadio[type="radio"]:not(:checked) + label:after,
.cmsRadio[type="radio"]:checked + label:after {
  content: "✕";
  font-style: normal;
  font-weight: 800;
  position: absolute;
  box-sizing: content-box;
  bottom: 5px;
  left: 3px;
  font-size: 15px;
  color: var(--optum-gray-1);
  line-height: 0;
}

/* checked mark aspect */
.cmsRadio[type="radio"]:not(:checked) + label.topLabel:after,
.cmsRadio[type="radio"]:checked + label.topLabel:after {
  content: "✕";
  font-style: normal;
  font-weight: 800;
  position: absolute;
  box-sizing: content-box;
  top: 23px;
  left: 2px;
  font-size: 15px;
  color: var(--optum-gray-1);
  line-height: 0;
}

/* checked mark aspect changes */
.cmsRadio[type="radio"]:not(:checked) + label:after {
  opacity: 0;
}

.cmsRadio[type="radio"]:checked + label:after {
  opacity: 1;
}

/* Disabled checkbox */
.cmsRadio[type="radio"]:disabled:not(:checked) + label:before,
.cmsRadio[type="radio"]:disabled:checked + label:before {
  box-shadow: none;
  /* border: 2px solid var(--optum-red-2); */
  border-color: var(--optum-red-2);
  background-color: var(--optum-white);
  box-sizing: content-box;
}

.cmsRadio[type="radio"]:disabled:checked + label:after {
  color: var(--optum-gray-3);
}

.cmsRadio[type="radio"]:disabled + label {
  color: var(--optum-gray-5);
}

/* Accessibility */
.cmsRadio[type="radio"]:checked:focus + label:before,
.cmsRadio[type="radio"]:not(:checked):focus + label:before {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
    0 0 0 6px rgba(203, 34, 237, 0.2);
}

/* Convoluted custom radio styles */

/* Base for label styling */
.cmsRadio[type="radio"]:not(:checked),
.cmsRadio[type="radio"]:checked {
  display: none;
}
.cmsRadio[type="radio"]:not(:checked) + label,
.cmsRadio[type="radio"]:checked + label {
  position: relative;
  padding-left: 17px;
  cursor: pointer;
}

/* radio aspect */
.cmsRadio[type="radio"]:not(:checked) + label:before,
.cmsRadio[type="radio"]:checked + label:before {
  content: "";
  position: absolute;
  box-sizing: content-box;
  left: 0;
  bottom: -3px;
  width: 13px;
  height: 13px;
  border: 2px solid var(--optum-red-2);
  background: var(--optum-white);
}

/* radio aspect positioning for italicized labels */
.cmsRadio[type="radio"]:not(:checked) + label.checkboxItalicLabel:before,
.cmsRadio[type="radio"]:checked + label.checkboxItalicLabel:before {
  left: -2px;
  bottom: -7px;
}

/* checked mark aspect */
.cmsRadio[type="radio"]:not(:checked) + label:after,
.cmsRadio[type="radio"]:checked + label:after {
  content: "✕";
  font-style: normal;
  font-weight: 800;
  position: absolute;
  box-sizing: content-box;
  bottom: 5px;
  left: 3px;
  font-size: 15px;
  color: var(--optum-gray-1);
  line-height: 0;
}

/* checked mark aspect positioning for italicized labels */
.cmsRadio[type="radio"]:not(:checked) + label.checkboxItalicLabel:after,
.cmsRadio[type="radio"]:checked + label.checkboxItalicLabel:after {
  left: 1px;
  bottom: 1px;
}

/* checked mark aspect changes */
.cmsRadio[type="radio"]:not(:checked) + label:after {
  opacity: 0;
}

.cmsRadio[type="radio"]:checked + label:after {
  opacity: 1;
}

/* Disabled radio */
.cmsRadio[type="radio"]:disabled:not(:checked) + label:before,
.cmsRadio[type="radio"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: var(--optum-red-2); 
  background-color: var(--optum-white);
  /* border-color: var(--optum-gray-5);
  background-color: var(--optum-gray-4); */
  box-sizing: content-box;
}

.cmsRadio[type="radio"]:disabled:checked + label:after {
  color: var(--optum-gray-3);
}

.cmsRadio[type="radio"]:disabled + label {
  color: var(--optum-red-2);
}

/* Accessibility */
.cmsRadio[type="radio"]:checked:focus + label:before,
.cmsRadio[type="radio"]:not(:checked):focus + label:before {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
    0 0 0 6px rgba(203, 34, 237, 0.2);
}



/* Base for left label styling */
.cmsRadioLeftLabel[type="radio"]:not(:checked),
.cmsRadioLeftLabel[type="radio"]:checked {
  display: none;
}
.cmsRadioLeftLabel[type="radio"]:not(:checked) + label,
.cmsRadioLeftLabel[type="radio"]:checked + label {
  display: block;
  padding-left: 0;
  cursor: pointer;
}

/* radio aspect */
.cmsRadioLeftLabel[type="radio"]:not(:checked) + label:after,
.cmsRadioLeftLabel[type="radio"]:checked + label:after {
  content: "";
  box-sizing: content-box;
  display: block;
  position: absolute;
  right: -7px;
  bottom: -3px;
  width: 13px;
  height: 13px;
  border: 2px solid var(--optum-red-2);
  background: var(--optum-white);
}

/* checked mark aspect */
.cmsRadioLeftLabel[type="radio"]:not(:checked) + label:before,
.cmsRadioLeftLabel[type="radio"]:checked + label:before {
  content: "✕";
  font-style: normal;
  font-weight: 800;
  box-sizing: content-box;
  display: block;
  position: absolute;
  right: -5px;
  bottom: 5px;
  z-index: 1;
  font-size: 15px;
  color: var(--optum-gray-1);
  line-height: 0;
}

/* checked mark aspect changes */
.cmsRadioLeftLabel[type="radio"]:not(:checked) + label:before {
  opacity: 0;
}

.cmsRadioLeftLabel[type="radio"]:checked + label:before {
  opacity: 1;
}

/* Disabled radio */
.cmsRadioLeftLabel[type="radio"]:disabled:not(:checked) + label:after,
.cmsRadioLeftLabel[type="radio"]:disabled:checked + label:after {
  box-shadow: none;
  border-color: var(--optum-red-2); 
  background-color: var(--optum-white);
  /* border-color: var(--optum-gray-5);
  background-color: var(--optum-gray-4); */
  box-sizing: content-box;
}

.cmsRadioLeftLabel[type="radio"]:disabled:checked + label:before {
  color: var(--optum-gray-3);
}

.cmsRadioLeftLabel[type="radio"]:disabled + label {
  color: var(--optum-red-2);
}

/* Accessibility */
.cmsRadioLeftLabel[type="radio"]:checked:focus + label:after,
.cmsRadioLeftLabel[type="radio"]:not(:checked):focus + label:after {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
    0 0 0 6px rgba(203, 34, 237, 0.2);
}

