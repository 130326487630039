.pdfButton {
    text-transform: uppercase;
    background-color:white;
    border:none;
    border-style: outset;
    font-size:10px;
    /* color:white; */
    /* -webkit-box-shadow: 0px 10px 5px -4px rgba(0,0,0,0.33);
    -moz-box-shadow: 0px 10px 5px -4px rgba(0,0,0,0.33);
    box-shadow: 0px 8px 5px -4px rgba(0,0,0,0.33); */
    width:90%;
    height: 65px;
    margin-top: 5px;
    margin-bottom: 5px;
}
/* 
.pdfButton:hover{
 -webkit-box-shadow: 0px 10px 5px -4px rgba(0,0,0,0.33);
    -moz-box-shadow: 0px 10px 5px -4px rgba(0,0,0,0.33);
    box-shadow: 0px 8px 5px -4px rgba(0,0,0,0.33);
    background-color: lightgray;
    
  } */

