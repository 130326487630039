.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.ag-row-hover {
  /* putting in !important so it overrides the theme's styling as it hovers the row also */
  background-color: gold !important;

  /* rgb(255, 240, 212) */
}

/*.ag-column-hover {
  background-color: orange ;
}*/

.ag-theme-balham .ag-row-selected {
  background-color: #ff612b !important;
  color: white !important;
  --ag-selected-row-background-color: rgba(245, 183, 0, 0.5) !important;
}

/* .ag-theme-balham .ag-ltr .ag-cell-focus {
  border: 1px solid red;
} */

/* header auto wrap */
.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
}

/* Status Bar Height*/
/* .ag-status-bar { min-height: 20px; } */
.ag-status-bar {
  height: 30px;
}

.border {
  box-shadow: inset 0.4em 0.4em 0.4em 0 rgba(255, 255, 255, 0.5),
    inset -0.4em -0.4em 0.4em 0 rgba(0, 0, 0, 0.5);
}

/* a:hover {
  background-color: gold !important;
} */

:root {
  --optum-accessible-orange: #eb4600; /* can be used for text */
  --optum-brand-orange: #ff612b;
  --optum-white: #ffffff;
  --optum-brand-gold: #f5b700;
  --optum-gray-1: #323334; /* can be used for text */
  --optum-gray-2: #4b4d4f; /* can be used for text */
  --optum-gray-3: #6d6f70; /* can be used for text */
  --optum-gray-4: #7d7f81;
  --optum-gray-5: #929496;
  --optum-gray-6: #cbcccd;
  --optum-gray-7: #e5e5e6;
  --optum-red-1: #990000; /* can be used for text */
  --optum-red-2: #d13f44;
  --optum-purple-1: #4b3383; /* can be used for text */
  --optum-purple-2: #5c4392;
  --optum-teal-1: #218371; /* can be used for text */
  --optum-teal-2: #42baa6;
  --optum-green-1: #238e1d; /* can be used for text */
  --optum-green-2: #72c968;
  --optum-text-link-blue: #0c55b8; /* can be used for text */
  --optum-text-link-dark-blue: #001d5b; /* can be used for text */
  --optum-error-state-red: #c40000; /* can be used for text */
  --optum-go-green-1: #61d661; /* can be used for text */
  --width: ;
  --full-width: 100vw;
  --magic-number: 0.09719;
  --skew-padding: calc(var(--width) * var(--magic-number));
  --clip-padding: calc(var(--full-width) * var(--magic-number));
}

a {
  color: var(--optum-text-link-blue) !important;
}

a:active {
  background-color: white !important;
}

.boxes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-gap: 3%; */
  margin: 2em 0;
  /* height: '1150px';
  width: '920px'; */

  --translation: 0;
}

.boxes2 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* grid-gap: 3%; */
  margin: 2em 0;
  /* height: '1150px';
  width: '920px'; */

  --translation: 0;
}

.boxes3 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* grid-gap: 3%; */
  margin: 2em 0;
  /* height: '1150px';
  width: '920px'; */

  --translation: 0;
}
.boxes4 {
  display: grid;
  grid-template-columns: repeat(65, 1fr);
  /* grid-gap: 3%; */
  margin: 2em 0;
  /* height: '1150px';
  width: '920px'; */

  --translation: 0;
}
.box1 {
  /* margin-left: 10%; */
  height: 1150px;
  width: "920px";
  z-index: 4;
}
.box2 {
  margin-left: -65%;
  height: 1150px;
  width: 920px;
  z-index: 3;
}
.box3 {
  height: 1150px;
  width: 920px;
  margin-left: -130%;
  z-index: 2;
}
.box4 {
  height: 1150px;
  width: 920px;
  margin-left: -195%;
  z-index: 1;
}

.content {
  max-width: 100vw;
  /* margin: 0 auto; */
  /* overflow-y: hidden; */
  /* padding: 1.5em; */
  position: relative;

  /* -----------
    enable the border to see, that the content
    perfectly fits into the section withou
    bleeding into the adjecting areas:
    ------------ */
  /* border: 2px dashed #fff8; */
}

/* .diagonal-box {
    overflow-y: hidden
  } */

.test1 {
  height: "100%";
  width: "100%";
  margin-right: -20%;
}

#nav-dropdown {
  color: white;
  background-color: white !important;
}

/* 
  On short ag-grids, popup panels (like the column chooser) would not display fully.
  This will set a static height for the panels to fit correctly.
*/
#dash-provider-grid .ag-panel,
#dash-package-grid .ag-panel,
#dash-payments-grid .ag-panel,
#dash-history-grid .ag-panel {
  min-height: 50% !important;
  max-height: 90% !important;
}
