.rejectionOptionsContainer {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  border: 1px solid var(--optum-brand-gold);
  padding: 5px 30px 5px 30px;
  background-color: var(--optum-white);
}

.form-check > .rejectionOptionsLabel {
  color: var(--optum-gray-1) !important;
  font-size: 14px;
}

[type="radio"]:not(:checked) + label.rejectionOptionsLabel:before,
[type="radio"]:checked + label.rejectionOptionsLabel:before {
  content: "";
  position: absolute;
  box-sizing: content-box;
  left: 0;
  bottom: 0;
  width: 8px;
  height: 8px;
  border: 2px solid var(--optum-brand-gold);
  border-radius: 8px;
  background-color: var(--optum-white);
}

[type="radio"]:checked + label.rejectionOptionsLabel:after {
  content: "";
}

[type="radio"]:checked + label.rejectionOptionsLabel:before {
  box-sizing: content-box;
  background-color: var(--optum-gray-1);
  border: 2px solid var(--optum-gray-1);
}

#modalRejectButton {
  width: 115px;
  height: 30px;
  border: none;
  font-weight: 800;
  padding: 5px;
  line-height: 1;
  border-radius: 0;
  font-size: 20px;
  margin: 10px;
  background-color: var(--optum-error-state-red);
}

#modalRejectButton.disabled,
#modalRejectButton:disabled {
  background-color: var(--optum-gray-7);
  color: var(--optum-white);
  opacity: 1;
}
